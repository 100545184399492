/**
 * Gets all numbers in the document that have the class "total" and colors them red if 
 * they are negative.
 */

const colorNegativeNumbers = () => {
  const totalElements = document.getElementsByClassName("total");
  const spanElements = Array.from(totalElements).map(element => element.querySelector("span"));
  spanElements.forEach(spanElement => {
    if (spanElement) {
      const contents = spanElement.childNodes[0].nodeValue
      if (contents.includes("-") && contents !== "-") {
        spanElement.style.color = "red";
      } else {
        spanElement.style.color = "black";
      }
    }
  });
};

export default colorNegativeNumbers;