import { Box, Typography, Button } from '@mui/material';
import Card from '@mui/material/Card';

import { ChevronDownIcon } from '@chakra-ui/icons';

import { VERSION } from "../../services/providers/settings";

const Title = (props) => {

  return (
    <Card variant="outlined" className='glass-green' sx={{ ml:2, p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width:"47%", boxShadow: 2 }}>
      <Typography variant="h5" >Daily Cost Page (v{VERSION})</Typography>
      <Typography variant="h6" >
        Dispatch: <strong>{props.data["frontend_data"]["current_week"]}</strong>
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <Button variant="contained" sx={{ bgcolor: 'bold.main' }}
          onClick={() => {
            var url = `api/v1/getjobcost/${props.data["frontend_data"]["prev_week"]}`;
            props.setIsLoading(true);
            props.setData(null);
            props.getData(url, props.setData).finally(() => props.setIsLoading(false));
          }}
        > Previous
        </Button>
        <Button variant="contained" disabled>
          Select Week <ChevronDownIcon />
        </Button>
        <Button variant="contained" sx={{ bgcolor: 'bold.main' }}
          onClick={() => {
            var url = `api/v1/getjobcost/${props.data["frontend_data"]["next_week"]}`;
            props.setIsLoading(true);
            props.setData(null);
            props.getData(url, props.setData).finally(() => props.setIsLoading(false));
          }}
        > Next
        </Button>
      </Box>
    </Card>
  );
};
export default Title;