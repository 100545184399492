
const green = "#cfcfcf"
const gray800 = "#bababa"
const gray300 = "#dbdbdb"
const gray200 = "#E9E9E9"
const gray100 = "#EBEBEB"
const gray50 = "#f3f3f3"


const styles = {
  global: {
    "html, body": {
      color: "gray.800",
      fontFamily: "Calibri, sans-serif",
    },
    "#root": {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      useColorScheme: "light !important",
    },
    svg: {
      cursor: "pointer",
    },
    "html": {
      background: "radial-gradient(circle, rgba(229,232,232,1) 0%, rgba(255,255,255,1) 100%)",
    },
    "body": {
      minWidth: "1024px",
      maxWidth: "1024px",
      width: "100vw",
      display: "block",
      marginRight: "auto",
      marginLeft: "auto",
      background: "none !important",
    },
    ".mainContainer": {
      backgroundColor: gray100,
      color: "gray.800",
      fontFamily: "Calibri, sans-serif",
      justifyContent: "center",
      textAlign: "center",
      paddingBottom: "14rem",
    },
    ".table": {
      border: "0px solid #424242",
      borderBottom: "0px"
    },
    ".tr": {
      display: "flex",
      width: "fit-content",
      bg: "gray.50",
    },
    ".th, .td": {
      boxShadow: "inset 0 0 0 0px #424242",
      padding: "0.1rem",
      paddingLeft: "0.5rem",
      backgroundColor: gray50,
    },
    ".th": {
      // backgroundColor: "gray.300",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "gray.900",
      padding: "0.1rem",
      fontWeight: "bold",
      fontSize: "xs",
      textTransform: "uppercase",
      textAlign: "center",
      backgroundColor: gray50,
      borderBottom: "1px solid " + gray800,
    },
    ".main-header": {
      backgroundColor: gray300,
    },
    ".td > input": {
      m: "1",
      padding: "0.2rem",
      bg: "transparent",
      maxW: "100%",
    },
    ".date-wrapper": {
      display: "flex",
      alignItems: "center",
      w: "100%",
      h: "100%",
    },
    ".resizer": {
      position: "absolute",
      opacity: 0,
      top: 0,
      right: 0,
      h: "100%",
      w: "5px",
      bg: "#27bbff",
      cursor: "col-resize",
      userSelect: "none",
      touchAction: "none",
      borderRadius: "6px",
    },
    ".resizer.isResizing": {
      bg: "#2eff31",
      opacity: 1,
    },
    "*:hover > .resizer": {
      opacity: 1,
    },
    ".bb": {
      borderBottom: "1px solid #424242",
    },
    ".th-dark": {
      bg: gray300,
    },
    ".th-mid": {
      bg: gray200,
    },
    ".th-main-dark": {
      bg: gray300,
    },
    ".th-main-mid": {
      bg: gray200,
    },
    ".tr-dark": {
      bg: gray200,
    },
    ".tr-mid": {
      bg: gray100,
    },
    ".th-green": {
      backgroundColor: green,
      color: "gray.900",
    },
    ".text-right": {
      textAlign: "right",
    },
    ".text-left": {
      textAlign: "left",
    },
    ".content-left": {
      justifyContent: "left",
    },
    ".content-right": {
      justifyContent: "right",
    },
    ".m-0": {
      margin: "0 !important",
    },
    ".p-0": {
      padding: "0 !important",
    },
    ".pl-1": {
      paddingLeft: "0.25rem !important",
    },
    ".pl-4": {
      paddingLeft: "1rem !important",
    },
    ".pl-8": {
      paddingLeft: "2rem !important",
    },
    ".pr-1": {
      paddingRight: "0.25rem !important",
    },
    ".pr-2": {
      paddingRight: "0.5rem !important",
    },
    ".pr-4": {
      paddingRight: "1rem !important",
    },
    ".pr-6": {
      paddingRight: "1.5rem !important",
    },
    ".pr-16": {
      paddingRight: "4.5rem !important",
    },
    ".dropdown": {
      backgroundColor: gray200,
      padding: "0.5rem",
    },
    ".fauxButton": {
      backgroundColor: "#dadfe4",
      padding: "0.3rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      height: "2rem",
      marginRight: "0.5rem",
      fontWeight: "bold",
    },
    ".hidden": {
      display: "none",
    },
    ".bold": {
      fontWeight: "bold",
    },

    // Temp CSS for visualizations
    ".visualizations": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      gap: "1vw",
      flexWrap: "wrap",
      backgroundColor: "#ddd",
      padding: "1em",
    },
    ".dataCard": {
      backgroundColor: gray100,
      borderRadius: "0.5em",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      padding: "1em",
    },
    ".weeklyCard": {
      width: "98%",
      height: "20rem",
    },
    ".dailyCard": {
      width: "48%",
      height: "24rem",
    },
    ".jobCard": {
      width: "48%",
      height: "24rem",
    },
    ".jobCardChart": {
      width: "100%",
      height: "24rem",
      borderBottomRadius: "0",
    },
    ".jobCardBttns": {
      borderTopRadius: "0",
      backgroundColor: gray100,
      padding: "4px",
      width: "100%",
      height: "5rem",
    },
    ".glass": {
      background: "rgba(221, 221, 221, 0.5) !important",
      backgroundColor: "rgba(221, 221, 221, 0.5) !important",
      backdropFilter: "blur(5px) !important",
    },
    ".glass-green": {
      background: "rgba(160, 175, 160, 0.5) !important",
      backgroundColor: "rgba(160, 175, 160, 0.5) !important",
      backdropFilter: "blur(5px) !important",
    }

  },
};

export default styles;