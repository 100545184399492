const ColoredLine = ({ color, width }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: "1px",
          width: width,
          marginTop: "20px",
          marginBottom: "20px",
          marginRight: "auto",
          marginLeft: "auto",
      }}
  />
);

export default ColoredLine;