import { Box } from "@chakra-ui/react";
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useState, useEffect } from "react";

import ReactiveHeader from "../../components/tables/reactiveHeader";
import getCols from "../../utils/getCols";

const JobMetrics = (props) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props]);

  const columns = getCols(data, props.type, 172);

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange"
  });

  const subHeaders = ["Number of Workers", "Scope Qtys", "Productivity", "Equipment Qty", "Concrete (Yards)", "Concrete (Yards/Person)"]

  return (
    <Box>
      <Box className="table" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} w={table.getTotalSize()}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Box className="tr" key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <ReactiveHeader key={header.id} header={header} index={index} fill={index !== 0 ? false : undefined} date={props.dates} />
            ))}
          </Box>
        ))}
        {table.getRowModel().rows.map((row) => (
          <Box className="tr" key={row.id}>
            {row.getVisibleCells().map((cell, index) => (
              <Box className={`td ${index === 0 ? "text-left m-0 p-0 pl-4 " :
                index !== 0 && index % 2 !== 0 ? "text-right tr-mid m-0 p-0 pr-6" : "text-right m-0 p-0 pr-6"}`} w={cell.column.getSize() - 25} key={cell.id} fontSize='xs' py="0.5">
                <p className={subHeaders.includes(row.original.header) && index === 0 ? "bold" : "pl-4"}>{cell.getValue()} {index !== 0 && row.original.unit ? row.original.unit : null}</p>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default JobMetrics;