import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';

const Overview = ({ data }) => {

  return (
    <Card variant="outlined" className='glass-green' sx={{ mr: 2, p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: "47%", boxShadow: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Weekly Overview</Typography>
      <Box as="table" width="100%" borderRadius="lg" overflow="hidden">
        <Box as="thead">
          <Box as="tr">
            <Box as="th" sx={{ p: 1, textAlign: "center", borderBottom: 1}}>Gross Margin</Box>
            <Box as="th" sx={{ p: 1, textAlign: "center", borderBottom: 1 }}>Avg Worker/Day</Box>
            <Box as="th" sx={{ p: 1, textAlign: "center", borderBottom: 1 }}>Rev/Person</Box>
          </Box>
        </Box>
        <Box as="tbody">
          <Box as="tr">
            <Box as="td" sx={{ pt: 1, textAlign: "center" }}>{data["metrics_data"]["gross_margin"].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({data["metrics_data"]["margin_percentage"]}%)</Box>
            <Box as="td" sx={{ pt: 1, textAlign: "center" }}>{data["metrics_data"]["average_workers_day"]}</Box>
            <Box as="td" sx={{ pt: 1, textAlign: "center" }}>{data["metrics_data"]["rev_per_worker"].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
export default Overview;