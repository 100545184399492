import axios from 'axios';
/**
 * Stores fascade function to fetch API data via Axios
 */

/**
 * 
 * @param {String} url The API url to fetch data from
 * @param {Function} setData The state setter function to store the API response data
 * @returns API response data (see API documentation for details)
 */
const getAPIData = async (url, setData) => {
  const apiUrl = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : "http://127.0.0.1:5000/"
  const response = await axios.get(`${apiUrl}/${url}`);
  setData(response.data)
}

export default getAPIData;