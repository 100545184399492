import { defaults } from "chart.js/auto";
import { Bar, Line } from 'react-chartjs-2';

import formatLabel from "../../utils/formatLabel";

import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';

const Visualizations = ({ data }) => {

  // docs: https://www.chartjs.org/

  // Global default options
  defaults.maintainAspectRatio = false;
  defaults.responsive = true;

  defaults.plugins.legend.position = 'bottom';
  defaults.plugins.legend.labels.boxWidth = 20;
  defaults.plugins.title.display = true;
  defaults.plugins.title.align = "start";
  defaults.plugins.title.font.size = 16;
  defaults.plugins.title.color = "black";

  defaults.font.size = 12;
  defaults.font.family = "Open Sans";

  var weeklyData = data.viz_data["weekly_costs"];
  var dailyData = data.viz_data["daily_costs"];
  var jobData = data.viz_data["job_costs"];

  return (
    <Card variant="outlined" className='glass' sx={{ my: 2, mx: 2, p: 2, height: "60vh", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" sx={{ mt: 2 }}>Weekly Cost Analysis</Typography>
      <Box sx={{ height: "25vh", width: "98%", mb: 2, pb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: 1 }}>
        {/* Weekly */}
        <Line
          data={{
            labels: weeklyData.map((data) => data.label),
            datasets: [
              {
                label: "Revenue",
                data: weeklyData.map((data) => data.revenue),
                fill: false,
                borderColor: "#4472c4",
                backgroundColor: "#4472c4",
              },
              {
                label: "Cost",
                data: weeklyData.map((data) => data.cost),
                fill: false,
                borderColor: "#ee8137",
                backgroundColor: "#ee8137",
              },
              {
                label: "Gross",
                data: weeklyData.map((data) => data.gross),
                fill: false,
                borderColor: "#a6a6a6",
                backgroundColor: "#a6a6a6",
              }
            ],
          }}
          options={{
            elements: {
              line: {
                // tension: 0.4,
              },
            },
            
          }}
        />
      </Box>
      <Box sx={{ width: "98%", height: "25vh", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ width: "48%", pb: 6 }}>
          {/* daily */}
          <Typography variant="h6" sx={{ mt: 2 }}>Daily Cost Analysis</Typography>
          <Bar
            data={{
              labels: dailyData.map((data) => data.label),
              datasets: [
                {
                  label: "Revenue",
                  data: dailyData.map((data) => data.revenue),
                  backgroundColor: "#4472c4",
                  // borderRadius: 10,
                  stack: 'Stack 0',
                },
                {
                  label: "Labor",
                  data: dailyData.map((data) => data.labor),
                  backgroundColor: "#292a63",
                  stack: 'Stack 1',
                },
                {
                  label: "Equipment",
                  data: dailyData.map((data) => data.equip),
                  backgroundColor: "#ee8138",
                  stack: 'Stack 1',
                },
                {
                  label: "Subcontractors",
                  data: dailyData.map((data) => data.sub),
                  backgroundColor: "#ffc000",
                  stack: 'Stack 1',
                },
                {
                  label: "Materials",
                  data: dailyData.map((data) => data.mats),
                  backgroundColor: "#a5a5a5",
                  stack: 'Stack 1',
                }
              ],
            }}
            options={{
              interaction: {
                intersect: false,
              },
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true
                }
              }
            }}

          />
        </Box>
        <Box sx={{ width: "48%"}}>
          {/* job */}
          <Typography variant="h6" sx={{ mt: 2 }}>Job Cost Analysis</Typography>
          <Bar
            data={{
              labels: jobData.map((data) => formatLabel(data.label, 8)),
              datasets: [
                {
                  label: "Revenue",
                  data: jobData.map((data) => data.revenue),
                  backgroundColor: "#4472c4",
                },
                {
                  label: "Cost",
                  data: jobData.map((data) => data.cost),
                  backgroundColor: "#ee8137",
                }
              ],
            }}
            options={{
              scales: {
                x: {
                  ticks: {
                    font: {
                      size: 8, // set the font size for x-axis labels
                    }
                  }
                },
              }
            }}
          />
        </Box>
      </Box>
    </Card>
  );
};
export default Visualizations;