import React from 'react';
import { useState } from "react";

import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import JobTable from "../../features/dailyCost/JobTable";
import HorizontalLine from '../../components/ui/HorizontalLine';

const JobTables = (props) => {

  const [jobs] = useState(props.data.jobs);

  return (
    <Box sx={{ mb: 40 }}>
      {jobs.map((job, index) => {
        return (
          <Box>
            {index === 0 ? <HorizontalLine key={'Line-1-' + index} color="black" width="90%" /> : null}
            <Card key={'jobCard-' + index} variant="outlined" sx={{ m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <JobTable key={index} data={job} />
            </Card>
            {index === 0 ? <HorizontalLine key={'Line-2-' + index} color="black" width="90%" /> : null}
          </Box>
        )
      })}
    </Box>
  );
};
export default JobTables;