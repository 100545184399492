import { Box, Button, ChakraProvider } from "@chakra-ui/react";
import chakraTheme from "../../theme/chakraTheme";

import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useState, useEffect } from "react";

import getCols from "../../utils/getCols";

import calcGross from "../../utils/calcGross";

import MainHeader from "../../components/tables/mainHeader";
import SubHeader from "../../components/tables/subHeader";
import JobMetrics from "./JobMetrics";

const Job = (props) => {

  props.data["gross"] = calcGross(props);

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data["date_headers"]);
  }, [props]);

  const columns = getCols(data);

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
  });

  const [show, setShow] = useState(false);

  return (
    <ChakraProvider theme={chakraTheme}>
    <Box>
      <Box className="table" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} w={table.getTotalSize()}>
        {table.getRowModel().rows.map((row) => (
          <Box p={0} className="tr" key={row.id}>
            {row.getVisibleCells().map((cell, index) => (
              <Box className={
                index === 0 ? "th th-dark content-left text-left" : index % 2 !== 0 ? "th th-green content-right pr-2" : "th th-dark content-right pr-2"} w={cell.column.getSize() - 25} key={cell.id}>
                {index === 0 &&
                  <Box p={0} pr={2} pt={1}>
                    <Button colorScheme='whiteAlpha' color={'black'} backgroundColor={'gray.400'} m={1} mr={2} ml={4} h="20px" w="25px" onClick={() => setShow(!show)}>{show ? <p>-</p> : <p>+</p>}</Button>
                  </Box>
                }
                <Box p={0} mr={4} pt={1}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Box>
              </Box>
            ))}
          </Box>
        ))}
        <MainHeader data={props.data["revenue"]} show={show} type="revenue" />
        <MainHeader data={props.data["cost"]} type="cost" />
        {show ?
          <Box>
            <SubHeader data={props.data["labor"]} type="labor" />
            <SubHeader data={props.data["equipment"]} type="equipment" />
            <SubHeader data={props.data["materials"]} type="materials" />
            <SubHeader data={props.data["subcontractors"]} type="subcontractors" />
          </Box> : null
        }
        <MainHeader data={props.data["gross"]} type="gross" />
        {show ?
          <Box my={4}>
            <JobMetrics data={props.data["metrics"]} dates={props.data["date_headers"]} type="metrics" />
          </Box> : null
        }
      </Box>
    </Box>
    </ChakraProvider>
  );
};
export default Job;