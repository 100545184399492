// Header that has a reactive design, depending on if it displaying a dollar value or 
// regualr string

import { Box, Icon } from "@chakra-ui/react";
import SortIcon from "../icons/SortIcon";
import React from "react";

const ReactiveHeader = (data) => {

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  var dark = "th-mid";
  var mid = "";

  if (data.type === "main") {
    dark = "th-main-dark";
    mid = "th-main-mid";
  } else if (data.type === "sub") {
    dark = "th-mid";
    mid = "";
  }

  const { header, index } = data;

  if (data.fill !== false) {
    return (
      <Box className={index !== 0 && index % 2 !== 0 ? `th ${dark}` : `th ${mid}`} w={header.getSize() - 25} key={header.id} fontWeight='semibold'>
        {index === 0 ?
          <Box pl={4}>{header.column.columnDef.header}</Box> : null
        }
        {index !== 0 ?
          <Box className="total" ml="auto" mr={0}>{header.column.columnDef.header}</Box> : null
        }
        {index === 0 ?
          <Box ml="auto" mr={10}>{
            header.column.getCanSort() && <Icon
              as={SortIcon}
              fontSize={14}
              onClick={
                header.column.getToggleSortingHandler()
              }
            />
          }</Box> : null
        }
        {index !== 0 ?
          <Box>{
            header.column.getCanSort() && <Icon
              as={SortIcon}
              pt={1}
              mr="auto"
              ml={1}
              fontSize={18}
              onClick={
                header.column.getToggleSortingHandler()
              }
            />
          }</Box> : null
        }

        <Box
          onMouseDown={
            header.getResizeHandler()
          }
          onTouchStart={
            header.getResizeHandler()
          }
          className={
            `resizer ${header.column.getIsResizing() ? "isResizing" : ""
            }`
          }
        />
      </Box>
    );
  };

  return (
    <Box className={index !== 0 && index % 2 !== 0 ? "th th-mid" : "th"} w={header.getSize() - 25} key={header.id} fontWeight='semibold'>
      <p key={"metricsDate" + index}>{data.date[0][days[index - 1]]}</p>
      <Box
        onMouseDown={
          header.getResizeHandler()
        }
        onTouchStart={
          header.getResizeHandler()
        }
        className={
          `resizer ${header.column.getIsResizing() ? "isResizing" : ""
          }`
        }
      />
    </Box>
  )
};

export default ReactiveHeader;
