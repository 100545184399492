import HeaderLabel from '../components/tables/headerLabel';

/**
 * Takes the data from the API and returns the columns data for TanStack Table
 * 
 * @param {Array} data The array of job data from the API (each element is a job)
 * @param {string} header Override for the header text
 * @param {Int} size Override for the column size
 * @returns Columns data formatted for TanStack Table
 */


function getCols(data, header = null, size = 130) {

  const columns = [
    {
      header: header ? header : null,
      accessorKey: "header",
      size: 233,
      cell: (props) => {
        const value = props.getValue();
        if (value === 0) {
          return <p>-</p>;
        } else {
          return <p>{props.getValue()}</p>;
        }
      }
    },
  ];

  var cols = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'total', 'percentage'];
  var digits = 2;

  if (header === 'metrics') {
    cols = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    digits = 2;
  }

  cols.forEach((day) => {
    columns.push({
      header: <HeaderLabel data={[data, day]} />,
      accessorKey: day,
      size: size,
      cell: (props) => {
        const value = props.getValue();
        if (typeof value === 'number') {
          if (value === 0) {
            return <p>-</p>;
          }
          return <p>{value.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits })}</p>;
        } else {
          return <p>{props.getValue()}</p>;
        }
      }
    });
  });
  return columns;
}

export default getCols;