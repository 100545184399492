import React, { useState, useEffect, Suspense } from 'react';

import getAPIData from '../services/api/axios';

import { BlinkBlur } from "react-loading-indicators";
import { Box, Typography } from '@mui/material';
import { Container } from '@mui/material';

import colorNegativeNumbers from "../utils/colorNegativeNumbers";

import JobTables from '../features/dailyCost/JobTables';
import Visualizations from '../features/dailyCost/visualizations';
import Title from '../features/dailyCost/Title';
import Overview from '../features/dailyCost/overview';

const DailyCost = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null) //initial state is null

  useEffect(() => {
    const url = "api/v1/getjobcost/current";
    getAPIData(url, setData).finally(() => {
      setIsLoading(false);
      setTimeout(() => {  // Small delay to allow page load to finish
        colorNegativeNumbers();
      }, 2000);
    });
  }, []);

  if (isLoading) {
    return (
      <Container sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}>
        <Box>
          <img alt="RS Logo" width="128px" height="128px" src="https://rsapp-mass-storage-prod.s3.us-west-2.amazonaws.com/static/logo_trans_fill_dark.png" />
        </Box>
        <Typography variant='h4' sx={{ my: 2 }}>R&S Daily Cost Page</Typography>
        <Typography variant='h5' sx={{ mb: 4 }}>Loading...</Typography>
        <Box>
          <BlinkBlur color="#121c12" size="medium" text="" textColor="" />
        </Box>
      </Container>
    );
  }

  return (
    data && <Container sx={{ minWidth: "1024px", maxWidth: "1024px" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 4, pt: 4 }}>
        <Title data={data}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setData={setData}
          getData={getAPIData} />
        <Overview data={data} />
      </Box>
      <Visualizations data={data} />
        <Suspense fallback={<div>Loading...</div>}>
          <JobTables data={data} />
        </Suspense>
    </Container>
  );

}

export default DailyCost;