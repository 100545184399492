import { createTheme } from '@mui/material';

const rsTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#002C08',
      light: 'A0CEA1',
      contrast: '#3DACFF',
      alt: '#FFFBCE',
    },
    secondary: {
      main: '#A0AFA0',
      dark: '#6D7B6D',
      contrast: '#005A77',
    },
    bold: {
      main: '#004B84',
      contrast: '#932C35',
    },
    background: {
      paper: '#f3f3f1',
      light: '#EFFDEF',
    },
    info: {
      main: '#005A77',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
  },
  shape: {
    borderRadius: 10,
  },
});
export default rsTheme;