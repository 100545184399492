import React from 'react';
import ReactDOM from 'react-dom/client';
import DailyCost from './pages/DailyCost';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import rsTheme from './theme/theme';

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider theme={rsTheme}>
      <CssBaseline />
      <DailyCost />
    </ThemeProvider>
  </React.StrictMode>
);