const HeaderLabel = (props) => {

  // Add up totals of each day (this sums all the cells in the rows below this column)
  var total = 0;
  if (typeof props.data[0] !== 'string') {
    props.data[0].forEach((item) => {
      total += item[props.data[1]];
    });
  }

  // Format output, including rounding close percentages
  if (total === 0) {
    total = '-';
  } else {
    total = total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    if (props.data[1] === "percentage") {
      if (total === "99.99") {
        total = "100.00";
      } else if (total === "0.00") {
        total = "100.00";
      }
      total = total + "%";
      if (total === "NaN%") {
        total = "-";
      }
    }
  }
  return (
    <div>
      <span>
        {total}
      </span>
    </div>
  );
};

export default HeaderLabel;