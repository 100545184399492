import { Box } from "@chakra-ui/react";
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useState, useEffect } from "react";

import getCols from "../../utils/getCols";
import ReactiveHeader from "./reactiveHeader";

const MainHeader = (props) => {

  const show = props.show;

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props]);

  const columns = getCols(data, props.type);

  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange"
  });

  return (
    <Box>
      <Box className="table" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} w={table.getTotalSize()}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Box className="tr" key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <ReactiveHeader key={header.id} header={header} index={index} type={"main"} />
            ))}
          </Box>
        ))}
        {show ?
          <Box>
            {table.getRowModel().rows.map((row) => (
              <Box className="tr" key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Box className={`td ${index === 0 ? "text-left m-0 p-0 pl-4" :
                    index !== 0 && index % 2 !== 0 ? "text-right tr-mid m-0 p-0 pr-6" : "text-right m-0 p-0 pr-6"}`} w={cell.column.getSize() - 25} key={cell.id} fontSize='xs' py="0.5">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Box>
                ))}
              </Box>
            ))}
          </Box> : null}
      </Box>
    </Box>
  );
};
export default MainHeader;